import LocalizedStrings from "react-localization";
import { localizationEn } from "./localization/en";
import { localizationIt } from "./localization/it";
import { localizationRu } from "./localization/ru";
import { localizationFr } from "./localization/fr";
import { localizationEs } from "./localization/es";
import { localizationDe } from "./localization/de";
import { localizationPl } from "./localization/pl";
import { localizationPt } from "./localization/pt";
import { localizationJa } from "./localization/ja";

export let strings = new LocalizedStrings({
  en: localizationEn,
  it: localizationIt,
  ru: localizationRu,
  fr: localizationFr,
  es: localizationEs,
  de: localizationDe,
  pl: localizationPl,
  pt: localizationPt,
  ja: localizationJa,
});
