import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faExpand,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import styles from "./GameToolbar.module.css";

const GameToolbar = ({ onLike, onDislike, onGameInfo, onEnterFullscreen }) => {
  return (
    <div className={styles.toolbar}>
      <div className={styles.leftSection}>
        {/* <button onClick={onLike}>
          <FontAwesomeIcon icon={faThumbsUp} />
        </button>
        <button onClick={onDislike}>
          <FontAwesomeIcon icon={faThumbsDown} />
        </button> */}
      </div>
      <div className={styles.rightSection}>
        <button className={styles.button} onClick={onGameInfo}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <button className={styles.button} onClick={onEnterFullscreen}>
          <FontAwesomeIcon icon={faExpand} />
        </button>
      </div>
    </div>
  );
};

GameToolbar.propTypes = {
  onLike: PropTypes.func.isRequired,
  onDislike: PropTypes.func.isRequired,
  onGameInfo: PropTypes.func.isRequired,
  onEnterFullscreen: PropTypes.func.isRequired,
};

export default GameToolbar;
