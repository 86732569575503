export let localizationRu = {
  language: "Russian",
  navbar: {
    games: "Игры",
    contact: "Связаться",
  },
  contact: {
    name: "Имя:",
    email: "Адрес электронной почты:",
    message: "Сообщение:",
    namePlaceholder: "Введите ваше имя",
    emailPlaceholder: "Введите ваш email",
    messagePlaceholder: "Введите ваше сообщение",
    sendButton: "Отправить",
  },
  home: {
    head: {
      title: "Премиум Пасьянс бесплатно | Osolitaire",
      description:
        "Испытайте искусство пасьянсов с нашей бесплатной и изысканной коллекцией пасьянсов премиум-класса.",
    },
    header: {
      title: "Добро пожаловать в Пасьянс",
      description: "Исследуйте разнообразие пасьянсов для игры и удовольствия.",
    },
    keywords:
      "пасьянс, Клондайк, Пирамид, Фрикел, Гольф, Спайдер, Трипикс, Юкон, Русский, Канфилд, карточные игры, онлайн карточные игры, классический пасьянс, играть в пасьянс онлайн",
    gameDescriptions: {
      FreeCell: {
        title: "Фрикел Пасьянс",
        description:
          "Почувствуйте азарт Фрикел Пасьянса. Проверьте свою стратегию, перемещая карты, чтобы выиграть каждую игру!",
      },
      Golf: {
        title: "Гольф Пасьянс",
        description:
          "Попадите на цифровые поля Гольф Пасьянса! Очистите поле, соединяя карты в этой расслабляющей и приятной игре в пасьянс.",
      },
      Spider: {
        title: "Спайдер Пасьянс",
        description:
          "Попадите в веселую паутину с Спайдер Пасьянсом! Укладывайте карты, чтобы завоевать восемь баз и стать мастером пасьянса.",
      },
      TriPeaks: {
        title: "Трипикс Пасьянс",
        description:
          "Поднимитесь на вершины волнения с Трипикс Пасьянсом! Совпадайте карты, чтобы обнаружить сокровища и наслаждайтесь вызовом этой увлекательной игры.",
      },
      Pyramid: {
        title: "Пирамид Пасьянс",
        description:
          "Раскройте тайны Пирамид Пасьянса! Пара карт, чтобы достичь вершины и раскрыть скрытые сокровища внутри пирамиды.",
      },
      Yukon: {
        title: "Юкон Пасьянс",
        description:
          "Отправляйтесь в путешествие с Юкон Пасьянсом! Хитро перемещайте карты, чтобы открыть базы и покорить эту приключенческую вариацию пасьянса.",
      },
      Russian: {
        title: "Русский Пасьянс",
        description:
          "Погрузитесь в мир Русского Пасьянса! Стройте восходящие последовательности мастей и наслаждайтесь уникальными вызовами, которые предлагает эта игра.",
      },
      Klondike: {
        title: "Клондайк Пасьянс",
        description:
          "Возьмите на себя классическое приключение Клондайк Пасьянса! Упорядочьте карты по убыванию и испытайте вечную радость пасьянса.",
      },
      Canfield: {
        title: "Канфилд Пасьянс",
        description:
          "Поставьте себя на испытание с Канфилд Пасьянсом! Стратегически перемещайте карты, чтобы построить последовательности мастей и покорить базы для удовлетворительной победы.",
      },
    },
    gameDescriptionsTitle: "Исследуйте наши пасьянсы",
    popularGamesTitle: "Популярные пасьянсы",
    featuredGames: {
      title: "Рекомендуемые игры",
      playNow: "Играть сейчас",
    },
  },
  gameDescription: {
    head: {
      klondike: {
        title: "Косынка - Играйте онлайн бесплатно",
        description:
          "Играйте в Косынку онлайн бесплатно! Наслаждайтесь классической Косынкой Солитер",
        keywords:
          "Клондайк Солитер, Классический Клондайк, Играйте в Клондайк онлайн, Карточная игра Клондайк, Бесплатный Клондайк Солитер",
      },
      golf: {
        title: "Гольф - Играйте онлайн бесплатно",
        description:
          "Играйте в Гольф Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Гольф Солитер",
        keywords:
          "Гольф Солитер, Карточная игра Гольф, Играйте в Гольф онлайн, Стратегия Гольф Солитер, Бесплатный Гольф Солитер",
      },
      yukon: {
        title: "Юкон - Играйте онлайн бесплатно",
        description:
          "Играйте в Юкон Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Юкон Солитер",
        keywords:
          "Юкон Солитер, Карточная игра Юкон, Играйте в Юкон онлайн, Пасьянс Юкон, Бесплатный Юкон Солитер",
      },
      pyramid: {
        title: "Пирамида - Играйте онлайн бесплатно",
        description:
          "Играйте в Пирамиду Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Пирамиды Солитер",
        keywords:
          "Пирамида Солитер, Египетская Пирамида, Играйте в Пирамиду онлайн, Карточная игра Пирамида, Бесплатный Пирамид Солитер",
      },
      freecell: {
        title: "Свободная ячейка - Играйте онлайн бесплатно",
        description:
          "Играйте в Свободную ячейку Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Свободной ячейки Солитер",
        keywords:
          "Свободная ячейка Солитер, Карточная игра Свободная ячейка, Играйте в Свободную ячейку онлайн, Стратегия Свободной ячейки Солитер, Бесплатный Свободная ячейка Солитер",
      },
      tripeaks: {
        title: "Трипикс - Играйте онлайн бесплатно",
        description:
          "Играйте в Трипикс Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Трипикс Солитер",
        keywords:
          "Трипикс Солитер, Карточная игра Трипикс, Играйте в Трипикс онлайн, Три вершины Солитер, Бесплатный Трипикс Солитер",
      },
      canfield: {
        title: "Кэнфилд - Играйте онлайн бесплатно",
        description:
          "Играйте в Кэнфилд Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Кэнфилд Солитер",
        keywords:
          "Кэнфилд Солитер, Кэнфилд Карточная игра, Играйте в Кэнфилд онлайн, Демон Солитер, Бесплатный Кэнфилд Солитер",
      },
      spider: {
        title: "Паук - Играйте онлайн бесплатно",
        description:
          "Играйте в Паук Солитер онлайн бесплатно! Наслаждайтесь классическим вызовом Паука Солитер",
        keywords:
          "Паук Солитер, Паук Карточная игра, Играйте в Паука онлайн, Многомастерный Паук, Бесплатный Паук Солитер",
      },
      russian: {
        title: "Русский пасьянс - Играйте онлайн бесплатно",
        description:
          "Играйте в Русский пасьянс онлайн бесплатно! Наслаждайтесь классическим вызовом Русского пасьянса",
        keywords:
          "Русский пасьянс, Русская карточная игра, Играйте в Русский онлайн, Русское терпение, Бесплатный Русский пасьянс",
      },
    },
    header: {
      golf: {
        title: "Играйте в Гольф-пасьянс онлайн бесплатно",
        content:
          "Играйте в Гольф-пасьянс онлайн бесплатно! Наслаждайтесь уникальным вариантом классической карточной игры. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      yukon: {
        title: "Играйте в Юкон-пасьянс онлайн бесплатно",
        content:
          "Играйте в Юкон-пасьянс онлайн бесплатно! Проверьте свои навыки в этой сложной карточной игре. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      pyramid: {
        title: "Играйте в Пирамиду-пасьянс онлайн бесплатно",
        content:
          "Играйте в Пирамиду-пасьянс онлайн бесплатно! Поставьте себя на испытание с этой увлекательной карточной игрой. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      freecell: {
        title: "Играйте в Фрикелл-пасьянс онлайн бесплатно",
        content:
          "Играйте в Фрикелл-пасьянс онлайн бесплатно! Поставьте себя на испытание с этой классической карточной игрой. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      tripeaks: {
        title: "Играйте в Три-Пики-пасьянс онлайн бесплатно",
        content:
          "Играйте в Три-Пики-пасьянс онлайн бесплатно! Наслаждайтесь захватывающей вариацией классической карточной игры. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      canfield: {
        title: "Играйте в Кэнфилд-пасьянс онлайн бесплатно",
        content:
          "Играйте в Кэнфилд-пасьянс онлайн бесплатно! Проверьте свои навыки с этой классической карточной игрой. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      spider: {
        title: "Играйте в Спайдер-пасьянс онлайн бесплатно",
        content:
          "Играйте в Спайдер-пасьянс онлайн бесплатно! Проверьте свои навыки с этой классической карточной игрой. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      klondike: {
        title: "Играйте в Косынку онлайн бесплатно",
        content:
          "Играйте в Косынку онлайн бесплатно! Наслаждайтесь классическим карточным весельем в любое время, в любом месте. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
      russian: {
        title: "Играйте в Русский Пасьянс онлайн бесплатно",
        content:
          "Играйте в Русский Пасьянс онлайн бесплатно! Наслаждайтесь уникальным вариантом классической карточной игры. Будьте опытным игроком или новичком, погрузитесь в мир бесконечного развлечения вместе с нами!",
      },
    },
    instructions: {
      klondike: {
        title: "Как играть в Пасьянс Клондайк",
        content:
          "Пасьянс Клондайк - это классическая карточная игра, проверяющая вашу стратегию и терпение. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Расставьте колоду в столбиках таблицы. Начните с одной карты в самом левом столбце, затем добавьте шесть карт в следующий столбец, пять в следующий и так далее.",
          "Стройте вниз с чередованием цветов. Например, вы можете поместить красное 6 на черное 7.",
          "Перемещайте карты между столбцами таблицы, чтобы обнаруживать скрытые карты. Можно перемещать последовательность карт, если они идут по убыванию и одного масть.",
          "Помещайте королей в пустые столбцы таблицы. Пустой столбец можно заполнить только королем.",
          "Стройте основы по возрастанию. Начните с туза и стройте до короля для каждой масти.",
        ],
        video: {
          src: "https://www.youtube.com/embed/oAB5fsN2uA8?si=XpMQXxnyr6nByAHs",
          title: "Инструкционное видео по Пасьянсу Клондайк",
        },
      },
      golf: {
        title: "Как играть в Гольф-Пасьянс",
        content:
          "Гольф-Пасьянс - это уникальная вариация классической карточной игры, требующая навыков и стратегии. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Начните с таблицы из семи столбцов, каждый содержащий одну открытую карту.",
          "Оставшаяся колода формирует запас, и верхняя карта помещается рубашкой вверх рядом с ней в качестве основы.",
          "Карты с таблицы можно перемещать на основу, если их ранг выше или ниже верхней карты основы, независимо от масти.",
          "Пустые столбцы таблицы можно заполнять любой доступной картой.",
          "Игра выиграна, когда все карты успешно перемещены на основу.",
        ],
        video: {
          src: "https://www.youtube.com/embed/W8gEKBayxFI?si=nD922GvSaEB_zGZS",
          title: "Инструкционное видео по Гольф-Пасьянсу",
        },
      },
      yukon: {
        title: "Как играть в Пасьянс Юкон",
        content:
          "Пасьянс Юкон - это сложная карточная игра, проверяющая ваши навыки. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Начните с таблицы из семи столбцов, каждый содержащий разное количество закрытых и открытых карт.",
          "Цель - построить каждую масть основы в порядке возрастания от туза до короля.",
          "Карты на таблице можно перемещать как последовательность, если они идут по убыванию и одной масти.",
          "Пустые места в таблице можно заполнять только королем или последовательностью, начинающейся с короля.",
          "Игра выиграна, когда все карты успешно перемещены на основу.",
        ],
        video: {
          src: "https://www.youtube.com/embed/bi04SVsuBK8?si=WqYMhGpO0BsG2_eW",
          title: "Инструкционное видео по Пасьянсу Юкон",
        },
      },
      pyramid: {
        title: "Как играть в Пасьянс Пирамида",
        content:
          "Пасьянс Пирамида - это увлекательная карточная игра, требующая тщательного планирования. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Начните с пирамиды карт, и цель - удалить все пары карт, дающих в сумме 13.",
          "Короли имеют значение 13 и могут быть удалены самостоятельно. Дамы имеют значение 12, Валеты - 11 и так далее.",
          "Карты можно удалять только в том случае, если их не закрывают другие карты.",
          "Вы можете брать карты из колоды, чтобы помочь вам обнаруживать и сопоставлять карты в пирамиде.",
          "Игра выиграна, когда все пары успешно удалены или пирамида полностью очищена.",
        ],
        video: {
          src: "https://www.youtube.com/embed/p5ebvIyz7T4?si=OB3VrGL1j1UfjjH1",
          title: "Инструкционное видео по Пасьянсу Пирамида",
        },
      },
      freecell: {
        title: "Как играть в Пасьянс пасьянс",
        content:
          "Пасьянс Пасьянс - это классическая карточная игра, проверяющая вашу стратегию. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Расставьте карты в восемь столбцов таблицы, первые четыре из них содержат по семь карт, а остальные четыре - по шесть карт.",
          "Стройте вниз столбцы таблицы с чередованием цветов. Например, вы можете поместить красное 6 на черное 7.",
          "Свободные ячейки действуют как временное хранилище. Используйте их стратегически, чтобы перемещать карты и получать доступ к скрытым картам.",
          "Стройте основы по возрастанию, начиная с туза. Каждая основа представляет собой масть.",
          "Игра выиграна, когда все карты перемещены в основы в правильном порядке.",
        ],
        video: {
          src: "https://www.youtube.com/embed/iAa_2_oRXJw?si=yW8ja6GriCwz17nb",
          title: "Инструкционное видео по Пасьянсу Пасьянс",
        },
      },
      tripeaks: {
        title: "Как играть в Пасьянс Трипикс",
        content:
          "Пасьянс Трипикс - захватывающая вариация классической карточной игры. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Очистите таблицу, выбирая карты, которые на единицу выше или ниже открытой карты внизу.",
          "Продолжайте очищать таблицу, пока не останется больше ходов.",
          "Используйте джокеры стратегически, чтобы обнаруживать скрытые карты и создавать новые возможности для хода.",
          "Игра выиграна, когда все карты удалены с таблицы.",
          "Стратегически планируйте свои ходы, чтобы достичь высокого счета и завершить каждый уровень.",
        ],
        video: {
          src: "https://www.youtube.com/embed/J8tFipxzsP4?si=QWJ0rCnKixrzdk61",
          title: "Инструкционное видео по Пасьянсу Трипикс",
        },
      },
      canfield: {
        title: "Как играть в Пасьянс Канфилд",
        content:
          "Пасьянс Канфилд - это классическая карточная игра, проверяющая ваши навыки. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Раздайте 13 карт лицом вниз в одну колоду (резерв). Верхняя карта из резерва открыта и может быть сыграна.",
          "Стройте таблицу по убыванию и чередуя цвета. Например, вы можете поместить черное 6 на красное 7.",
          "Перемещайте карты на основу по возрастанию, начиная с тузов.",
          "Пустые столбцы таблицы можно заполнять только королем.",
          "Игра выиграна, когда все карты перемещены на основу.",
        ],
        video: {
          src: "https://www.youtube.com/embed/EwcHlxtCt9U?si=RcdxILp4_9jarjNV",
          title: "Инструкционное видео по Пасьянсу Канфилд",
        },
      },
      spider: {
        title: "Как играть в Пасьянс Спайдер",
        content:
          "Пасьянс Спайдер - это классическая карточная игра, проверяющая ваши навыки. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Раздайте 54 карты в 10 стопок таблицы, при этом только верхняя карта изначально открыта.",
          "Создавайте последовательности карт по убыванию в таблице, независимо от масти. Король может быть перемещен на пустое место.",
          "Завершайте последовательности из 13 карт от короля до туза, чтобы удалить их из таблицы.",
          "Стройте масти в области основы по возрастанию, начиная с туза.",
          "Игра выиграна, когда все масти построены в основе.",
        ],
        video: {
          src: "https://www.youtube.com/embed/pZVH6EfYHhI?si=HtKMwRZgL_fR-85R",
          title: "Инструкционное видео по Пасьянсу Спайдер",
        },
      },
      russian: {
        title: "Как играть в Русский Пасьянс",
        content:
          "Русский Пасьянс - это уникальная вариация классической карточной игры. Следуйте этим шагам, чтобы насладиться игрой:",
        steps: [
          "Начните с обычной колоды из 52 карт и раздайте их на 13 куч по 4 карты в каждой.",
          "Стройте вниз с чередованием цветов. Например, вы можете поместить красное 6 на черное 7.",
          "Перемещайте карты между кучами, чтобы обнаруживать скрытые карты. Можно перемещать последовательность карт, если они идут по убыванию и одной масти.",
          "Пустые кучи можно заполнять только королями.",
          "Стройте основы по возрастанию. Начните с туза и стройте до короля для каждой масти.",
          "Игра выиграна, когда все карты успешно перемещены в основы.",
        ],
        video: {
          src: "https://www.youtube.com/embed/y8az6GsDP80?si=HbYMYA2r4VtYhZh1",
          title: "Инструкционное видео по Русскому Пасьянсу",
        },
      },
    },
    strategy: {
      title: "Стратегия игры",
      content:
        "Овладение Клондайк Пасьянс требует как навыков, так и стратегии. Вот несколько советов, чтобы улучшить свою игру:",
      tips: [
        "Всегда отдавайте предпочтение обнаружению скрытых карт в таблице.",
        "Попробуйте создавать пустые столбцы таблицы для перемещения королей.",
        "Мудро стройте свои основы, чтобы создать место для движений в таблице.",
        "Не бойтесь отменять ходы и исследовать различные стратегии.",
        "Планируйте свои ходы заранее, чтобы повысить свои шансы на победу.",
      ],
    },
    detailedDescription: {
      klondike: {
        title: "Играйте в Клондайк Пасьянс онлайн",
        content:
          "Погрузитесь в классическое карточное приключение с нашим онлайн Клондайк Пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по знакомым правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Клондайк Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fklondike-screenshot.png?alt=media&token=5c57c3b2-b363-4ff1-99f6-a569854ab079",
      },
      golf: {
        title: "Играйте в Гольф Пасьянс онлайн",
        content:
          "Погрузитесь в уникальное карточное приключение с нашим онлайн Гольф Пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по уникальным правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Гольф Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fgolf-screenshot.png?alt=media&token=6dc8ee07-6e51-472b-bea9-aafce0d8ed58",
      },
      yukon: {
        title: "Играйте в Юкон Пасьянс онлайн",
        content:
          "Погрузитесь в сложное карточное приключение с нашим онлайн Юкон Пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по сложным правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Юкон Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fyukon-screenshot.png?alt=media&token=70fcc43b-8f1d-4385-a9c6-843f697ae65b",
      },
      pyramid: {
        title: "Играйте в Пирамиду Пасьянс онлайн",
        content:
          "Погрузитесь в интригующее карточное приключение с нашим онлайн Пирамидой Пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по интригующим правилам пасьянса. Будь вы опытным игроком в карты или новичком, наша Пирамида Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fpyramid-screenshot.png?alt=media&token=1cce4d5c-6e75-48c5-becb-2564f5913947",
      },
      freecell: {
        title: "Играйте в Фрикелл Пасьянс онлайн",
        content:
          "Погрузитесь в классическое карточное приключение с нашим онлайн Фрикелл Пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по классическим правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Фрикелл Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Ffreecell-screenshot.png?alt=media&token=b460ed7b-2f67-40a8-a446-23ab22cd3835",
      },
      tripeaks: {
        title: "Играйте в Трипикс Пасьянс онлайн",
        content:
          "Погрузитесь в захватывающее карточное приключение с нашим онлайн Трипикс Пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по захватывающим правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Трипикс Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Ftripeaks-screenshot.png?alt=media&token=06ea0716-b951-4656-92a4-90165b5cecfb",
      },
      canfield: {
        title: "Играйте в Канфилд пасьянс онлайн",
        content:
          "Погрузитесь в классическое карточное приключение с нашим онлайн Канфилд пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по классическим правилам пасьянс. Будь вы опытным игроком в карты или новичком, наш Канфилд пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fcanfield-screenshot.png?alt=media&token=e1c37d47-1b89-4fd7-af90-31497a4f5664",
      },
      spider: {
        title: "Играйте в Спайдер пасьянс онлайн",
        content:
          "Погрузитесь в классическое карточное приключение с нашим онлайн Спайдер пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по классическим правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Спайдер пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fspider-screenshot.png?alt=media&token=09e40372-4ba7-4124-8cdd-f5de004c01a8",
      },
      russian: {
        title: "Играйте в Русский Пасьянс онлайн",
        content:
          "Погрузитесь в уникальное карточное приключение с нашим онлайн Русским пасьянс. Наслаждайтесь бесконечными часами развлечений, стратегизируя и играя по уникальным правилам пасьянса. Будь вы опытным игроком в карты или новичком, наш Русский Пасьянс предлагает удобный интерфейс для приятного игрового опыта.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Frus-screenshot.png?alt=media&token=acf3ae0a-373c-40da-8a2b-c8c2165bcff9",
      },
      features: [
        {
          title: "Легко играть",
          content:
            "Интуитивное управление для приятного игрового опыта, подходящее для игроков всех уровней мастерства.",
        },
        {
          title: "Адаптивный дизайн",
          content:
            "Играйте в Клондайк пасьянс на различных устройствах, включая настольные компьютеры, планшеты и мобильные телефоны.",
        },
        {
          title: "Неограниченные отмены",
          content:
            "Отменяйте свои ходы без ограничений, позволяя вам совершенствовать свою стратегию.",
        },
        {
          title: "Бесконечные игры",
          content:
            "Играйте в неограниченное количество игр и наслаждайтесь опытом Клондайк пасьянс без ограничений.",
        },
      ],
    },
    faq: {
      title: "Часто задаваемые вопросы",
      questions: [
        {
          question: "Доступно ли мобильное приложение для вашего сервиса?",
          answer:
            "Да, у нас есть мобильное приложение для устройств на Android. Вы можете скачать его из Google Play.",
        },
        {
          question: "Действительно ли ваша игра в пасьянс полностью бесплатна?",
          answer:
            "Конечно! Наша игра в пасьянс полностью бесплатна, предоставляя неограниченное развлечение без скрытых платежей.",
        },
        {
          question:
            "Нужно ли мне создавать учетную запись, чтобы наслаждаться вашей бесплатной игрой в пасьянс?",
          answer:
            "Регистрация не требуется! Погружайтесь в нашу бесплатную игру в пасьянс без лишних хлопот, без необходимости регистрации.",
        },
        {
          question:
            "Насколько безопасными являются мои данные при игре в вашу бесплатную игру в пасьянс?",
          answer:
            "Безопасность ваших данных - наш приоритет. Наша бесплатная игра в пасьянс использует надежные средства шифрования и следует стандартам отрасли для обеспечения конфиденциальности и целостности ваших персональных данных.",
        },
      ],
      askButton: "Задать вопрос",
    },
    reviews: {
      title: "Отзывы наших игроков",
      reviews: [
        {
          content:
            '"Отличная игра! Просто не могу наиграться. Очень рекомендую!"',
          stars: 5,
          author: "Дмитрий Иванов",
        },
        {
          content: '"Просто и захватывающе. Идеально для короткого перерыва."',
          stars: 5,
          author: "Екатерина Петрова",
        },
        {
          content:
            '"Удивительная игра! Отличный способ расслабиться после долгого дня. Управление интуитивно понятное, и интерфейс приятный."',
          stars: 4,
          author: "Михаил Кузнецов",
        },
      ],
      leaveReviewButton: "Оставить отзыв",
    },
    socialMedia: {
      title: "Подписывайтесь на нас",
      icons: [
        { link: "https://www.youtube.com/@Osolitaire", icon: "faYoutube" },
        {
          link: "https://www.facebook.com/profile.php?id=61554153020153",
          icon: "faFacebook",
        },
        { link: "https://twitter.com/osolitairecom", icon: "faXTwitter" },
        { link: "https://vk.com/club223614034", icon: "faVk" },
      ],
    },
    playNow: {
      title: "Играйте сейчас!",
      content: "Почувствуйте волнение игры в Клондайк пасьянс.",
      cta: "Играть сейчас!",
    },
  },
  footer: "2024 Osolitaire.com. Все права защищены.",
};
