import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faXTwitter,
  faVk,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPlay,
  faMobile,
  faUndo,
  faInfinity,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { strings } from "../../assets/localization";
import styles from "./game_details.module.css";
import { useNavigate } from "react-router-dom";

const GameDetails = ({ gameId, onPlay, reference }) => {
  const navigateTo = useNavigate();

  return (
    <div ref={reference} className={styles.gameDescription}>
      <div className={styles.container}>
        <header>
          <h1>{strings.gameDescription.header[gameId].title}</h1>
          <p>{strings.gameDescription.header[gameId].content}</p>
        </header>

        <section className={`${styles.instructions} ${styles.section}`}>
          <h2>{strings.gameDescription.instructions[gameId].title}</h2>
          <p>{strings.gameDescription.instructions[gameId].content}</p>
          <ol>
            {strings.gameDescription.instructions[gameId].steps.map(
              (step, index) => (
                <li key={index}>{step}</li>
              )
            )}
          </ol>
          <div className={styles.videoContainer}>
            <iframe
              className={styles.iframe}
              src={strings.gameDescription.instructions[gameId].video.src}
              title={strings.gameDescription.instructions[gameId].video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        <section className={`${styles.strategy} ${styles.section} `}>
          <h2>{strings.gameDescription.strategy.title}</h2>
          <p>{strings.gameDescription.strategy.content}</p>
          <ul>
            {strings.gameDescription.strategy.tips.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        </section>

        <section
          className={`${styles.gameDescriptionDetailed} ${styles.section}`}
        >
          <h2>{strings.gameDescription.detailedDescription[gameId].title}</h2>
          <p>{strings.gameDescription.detailedDescription[gameId].content}</p>
          <div className={styles.feature}>
            <FontAwesomeIcon icon={faPlay} className={styles.featureIcon} />
            <div className={styles.featureText}>
              <h4>
                {strings.gameDescription.detailedDescription.features[0].title}
              </h4>
              <p>
                {
                  strings.gameDescription.detailedDescription.features[0]
                    .content
                }
              </p>
            </div>
          </div>
          <div className={styles.feature}>
            <FontAwesomeIcon icon={faMobile} className={styles.featureIcon} />
            <div className={styles.featureText}>
              <h4>
                {strings.gameDescription.detailedDescription.features[1].title}
              </h4>
              <p>
                {
                  strings.gameDescription.detailedDescription.features[1]
                    .content
                }
              </p>
            </div>
          </div>
          <div className={styles.feature}>
            <FontAwesomeIcon icon={faUndo} className={styles.featureIcon} />
            <div className={styles.featureText}>
              <h4>
                {strings.gameDescription.detailedDescription.features[2].title}
              </h4>
              <p>
                {
                  strings.gameDescription.detailedDescription.features[2]
                    .content
                }
              </p>
            </div>
          </div>
          <div className={styles.feature}>
            <FontAwesomeIcon icon={faInfinity} className={styles.featureIcon} />
            <div className={styles.featureText}>
              <h4>
                {strings.gameDescription.detailedDescription.features[3].title}
              </h4>
              <p>
                {
                  strings.gameDescription.detailedDescription.features[3]
                    .content
                }
              </p>
            </div>
          </div>
          {/* Repeat similar blocks for other features */}
          <img
            src={strings.gameDescription.detailedDescription[gameId].screenshot}
            alt="Klondike Solitaire Game"
          />
        </section>

        <section className={`${styles.faq} ${styles.section}`}>
          <h2>{strings.gameDescription.faq.title}</h2>
          <ul className={styles.faqList}>
            {strings.gameDescription.faq.questions.map((faq, index) => (
              <li className={styles.faqItem} key={index}>
                <h3 className={styles.faqQuestion}>{faq.question}</h3>
                <p className={styles.faqAnswer}>{faq.answer}</p>
              </li>
            ))}
          </ul>
          <button
            className={styles.askButton}
            onClick={() => {
              navigateTo("/contact");
            }}
          >
            {strings.gameDescription.faq.askButton}
          </button>
        </section>

        <section className={`${styles.multiSectionOne} ${styles.section}`}>
          <div id="yandex_rtb_R-A-2535917-3"></div>
          {window.yaContextCb.push(() => {
            // eslint-disable-next-line
            Ya.Context.AdvManager.render({
              blockId: "R-A-2535917-3",
              renderTo: "yandex_rtb_R-A-2535917-3",
            });
          })}
        </section>

        <section className={`${styles.reviews} ${styles.section}`}>
          <h2>{strings.gameDescription.reviews.title}</h2>
          <div className={styles.reviewContainer}>
            {strings.gameDescription.reviews.reviews.map((review, index) => (
              <div className={styles.review} key={index}>
                <p>{review.content}</p>
                {Array.from({ length: review.stars }, (_, i) => (
                  <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    className={styles.starIcon}
                  />
                ))}
                <br />
                <span className={styles.author}>— {review.author}</span>
              </div>
            ))}
          </div>
          <button
            className={styles.leaveReviewButton}
            onClick={() => {
              navigateTo("/contact");
            }}
          >
            {strings.gameDescription.reviews.leaveReviewButton}
          </button>
        </section>

        <section className={`${styles.socialMedia} ${styles.section}`}>
          <h2>{strings.gameDescription.socialMedia.title}</h2>
          <ul>
            <li>
              <a
                href="https://www.youtube.com/@Osolitaire"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  className={styles.youtubeIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61554153020153"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className={styles.facebookIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/osolitairecom"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  className={styles.twitterIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://vk.com/club223614034"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon icon={faVk} className={styles.vkIcon} />
              </a>
            </li>
          </ul>
        </section>

        <section className={`${styles.playNow} ${styles.section}`}>
          <h2>{strings.gameDescription.playNow.title}</h2>
          <p>
            {strings.gameDescription.playNow.content}{" "}
            <a onClick={onPlay}>{strings.gameDescription.playNow.cta}</a>
          </p>
        </section>

        <footer>
          <p>&copy; {strings.footer}</p>
        </footer>
      </div>
    </div>
  );
};

GameDetails.propTypes = {
  gameId: PropTypes.string.isRequired,
  onPlay: PropTypes.func.isRequired,
  reference: PropTypes.any.isRequired,
  onGameInfo: PropTypes.func.isRequired,
  onEnterFullscreen: PropTypes.func.isRequired,
};

export default GameDetails;
