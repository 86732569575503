import { Helmet } from "react-helmet";
import { strings } from "../../assets/localization";

const MainPageMeta = () => {
  return (
    <>
      <Helmet>
        {/* Title Tag */}
        <title>{strings.home.head.title}</title>

        {/* Meta Description */}
        <meta name="description" content={strings.home.head.description} />

        <link rel="icon" type="image/svg+xml" href="./icon.png" />

        {/* Meta Keywords (optional) */}
        <meta name="keywords" content={strings.home.head.keywords} />

        {/* Viewport for Responsive Design */}
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}

        {/* Open Graph Tags */}
        <meta property="og:title" content={strings.home.head.title} />
        <meta
          property="og:description"
          content={strings.home.head.description}
        />
        <meta property="og:image" content={strings.home.head.image} />
        <meta property="og:url" content="https://osolitaire.com" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content={strings.home.head.image} />
        <meta name="twitter:title" content={strings.home.head.title} />
        <meta
          name="twitter:description"
          content={strings.home.head.description}
        />
        <meta name="twitter:image" content="./icon.png" />

        {/* Robots Meta Tag */}
        <meta name="robots" content="index, follow" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://osolitaire.com" />

        {/* Author Meta Tag */}
        <meta name="author" content="MLIT" />

        {/* Language Meta Tag */}
        <meta name="language" content={strings.language} />

        {/* Page Type Meta Tag */}
        <meta name="page-type" content="Home" />

        {/* Revisit After Meta Tag */}
        <meta name="revisit-after" content="7 days" />

        {/* Google Analytics (if applicable) */}
        {/* Replace 'Your-GA-Code' with your Google Analytics tracking code */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-5M3FNT8N2W`}
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-5M3FNT8N2W');
        `}
        </script>
      </Helmet>
    </>
  );
};

export default MainPageMeta;
