import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { strings } from "../../assets/localization";

const GamePageMeta = ({ gameId, logo }) => {
  return (
    <Helmet>
      {/* Title Tag */}
      <title>{strings.gameDescription.head[gameId].title}</title>

      {/* Meta Description */}
      <meta
        name="description"
        content={strings.gameDescription.head[gameId].description}
      />

      <link rel="icon" type="image/svg+xml" href={logo} />

      {/* Meta Keywords (optional) */}
      <meta
        name="keywords"
        content={strings.gameDescription.head[gameId].keywords}
      />

      {/* Viewport for Responsive Design */}
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}

      {/* Open Graph Tags */}
      <meta
        property="og:title"
        content={strings.gameDescription.head[gameId].title}
      />
      <meta
        property="og:description"
        content={strings.gameDescription.head[gameId].desdcription}
      />
      <meta
        property="og:image"
        content={strings.gameDescription.detailedDescription[gameId].screenshot}
      />
      <meta
        property="og:url"
        content={"https://osolitaire.com/game/" + gameId}
      />

      {/* Twitter Card Tags */}
      <meta
        name="twitter:card"
        content={strings.gameDescription.detailedDescription[gameId].screenshot}
      />
      <meta
        name="twitter:title"
        content={strings.gameDescription.head[gameId].title}
      />
      <meta
        name="twitter:description"
        content={strings.gameDescription.head[gameId].description}
      />
      <meta
        name="twitter:image"
        content={strings.gameDescription.detailedDescription[gameId].screenshot}
      />

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />

      {/* Canonical URL */}
      <link rel="canonical" href={"https://osolitaire.com/game/" + gameId} />

      {/* Author Meta Tag */}
      <meta name="author" content="MLIT" />

      {/* Language Meta Tag */}
      <meta name="language" content={strings.language} />

      {/* Page Type Meta Tag */}
      <meta name="page-type" content="Game" />

      {/* Revisit After Meta Tag */}
      <meta name="revisit-after" content="7 days" />

      {/* Google Analytics (if applicable) */}
      {/* Replace 'Your-GA-Code' with your Google Analytics tracking code */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=G-5M3FNT8N2W`}
      />
      <script>
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-5M3FNT8N2W');
  `}
      </script>
    </Helmet>
  );
};

GamePageMeta.propTypes = {
  gameId: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default GamePageMeta;
