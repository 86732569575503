import styles from "./navbar.module.css";
import { useNavigate } from "react-router-dom";
import { strings } from "../../assets/localization";

function NavigationBar() {
  const navigateTo = useNavigate();

  return (
    <nav>
      <button
        onClick={() => {
          navigateTo("/");
        }}
      >
        {strings.navbar.games}
      </button>
      <button
        onClick={() => {
          navigateTo("/contact");
        }}
      >
        {strings.navbar.contact}
      </button>
    </nav>
  );
}

export default NavigationBar;
