import styles from "./gameCard.module.css";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function GameCard(props) {
  const name = props.name;
  const data = props.value;
  // const navigateTo = useNavigate();
  if (data) {
    return (
      <a
        className={styles.gameCard}
        href={`/game/${name}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={data.logo} />
        <p>{data.name}</p>
      </a>
    );
  }
}

GameCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default GameCard;
