/* eslint-disable no-undef */
import styles from "./home.module.css";

import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useEffect, useLayoutEffect, useState } from "react";
import GameCard from "../../components/GameCard/GameCard";
import NavigationBar from "../../components/Navbar/Navbar";
import { TailSpin } from "react-loader-spinner";
import { HomePageDetails } from "./HomePageDetails";
import MainPageMeta from "../../components/helmet/MainPageMeta";

function HomePage() {
  console.log("Home page is loading");
  const [allGames, setAllGames] = useState(null);
  // let language = navigator.language || navigator.userLanguage || "en";
  // console.log("Default Language : ", language);
  // if (
  //   language.toLowerCase().includes("en") ||
  //   !language.toLowerCase().includes("ru")
  // )
  // console.log("Modified language : ", language, language.toLowerCase().includes("en"));

  const [games, setGames] = useState(null);
  const [pageSize, setPageSize] = useState([0, 0]);
  let browserLanguage = navigator.language || navigator.userLanguage || "en";
  let language = "en";

  console.log("BROWSER_LANG: " + browserLanguage);

  if (browserLanguage.includes("ru")) {
    language = "ru";
  } else {
    language = "en";
  }

  // language = "ru";

  // Page resize

  useLayoutEffect(() => {
    function updateSize() {
      setPageSize([window.innerWidth, window.innerHeight]);
      console.log("PAGE_RESIZE: resized");
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  //Fetching Games
  useEffect(() => {
    // const storedGames = sessionStorage.getItem("games");

    // if (!JSON.parse(storedGames)) {
    //   setAllGames(JSON.parse(storedGames));
    //   return;
    // }

    let localGames = {};
    async function getGameDocuments() {
      console.log("fetching games");

      const querySnapshot = await getDocs(collection(db, "games"));

      querySnapshot.forEach((doc) => {
        localGames = { ...localGames, [doc.id]: doc.data() };
      });

      sessionStorage.setItem("games", JSON.stringify(localGames));
      localStorage.setItem("games", JSON.stringify(localGames));

      setAllGames(localGames);
    }

    getGameDocuments();
  }, []);

  //Setting current games based on the selected language
  useEffect(() => {
    if (allGames) {
      let games = allGames[language];

      setGames(games);
    }
  }, [allGames]);

  return (
    <>
      <MainPageMeta />
      <NavigationBar />
      <div className={styles.appContainer}>
        <div className={styles.gamesListSection}>
          <div className={styles.gamesList}>
            {games ? (
              <div className={styles.gamesGrid}>
                {Object.entries(games).map(([key, value]) => {
                  return (
                    <GameCard
                      className={styles.gameItem}
                      key={key}
                      name={key}
                      value={value}
                    />
                  );
                })}
              </div>
            ) : (
              <TailSpin
                height="80"
                width="80"
                color="#f7f7f7"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )}
          </div>

          <div className={styles.multiContainer}>
            <div id="yandex_rtb_R-A-2535917-1"></div>

            {window.yaContextCb.push(() => {
              Ya.Context.AdvManager.render({
                blockId: "R-A-2535917-1",
                renderTo: "yandex_rtb_R-A-2535917-1",
              });
            })}
          </div>
        </div>

        {games && (
          <HomePageDetails
            gamesList={Object.entries(games).map(([key, value]) => {
              value.key = key;
              return value;
            })}
          />
        )}
      </div>
    </>
  );
}

export default HomePage;
