import { Link } from "react-router-dom";
import styles from "./home-details.module.css";
import { strings } from "../../assets/localization";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faVk,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

export const HomePageDetails = ({ gamesList }) => {
  //   gamesList
  //     .slice(0, 3)
  //     .map((game, index) => console.log("GAME_DETAILS: " + JSON.stringify(game)));

  const gameDescriptions = strings.home.gameDescriptions;
  const navigateTo = useNavigate();

  return (
    <div className={styles.homePage}>
      <div className={styles.container}>
        <header>
          <h1>{strings.home.header.title}</h1>
          <p>{strings.home.header.description}</p>
        </header>

        <section
          className={`${styles.gameDescriptionsSection} ${styles.section}`}
        >
          <h2>{strings.home.gameDescriptionsTitle}</h2>
          <div className={styles.gameDescriptionsContainer}>
            {Object.keys(gameDescriptions).map((game, index) => (
              <div key={index} className={styles.gameDescription}>
                <h2>{gameDescriptions[game].title}</h2>
                <p>{gameDescriptions[game].description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className={`${styles.featuredGames} ${styles.section}`}>
          <h2>{strings.home.featuredGames.title}</h2>
          <div className={styles.featuredGamesList}>
            {gamesList.slice(0, 3).map((game, index) => (
              <div className={styles.featuredGame} key={index}>
                <img src={game.logo} alt={`${game.name} Thumbnail`} />
                <h3>{game.name}</h3>
                <p>{game.description}</p>
                <Link to={`/game/${game.key}`}>
                  {strings.home.featuredGames.playNow}
                </Link>
              </div>
            ))}
          </div>
        </section>

        <section className={`${styles.gamesList} ${styles.section}`}>
          <h2>{strings.home.popularGamesTitle}</h2>
          <ul>
            {gamesList.map((game, index) => (
              <li key={index}>
                <Link to={`/game/${game.key}`}>
                  <h3>{game.name}</h3>
                  <p>{game.description}</p>
                </Link>
              </li>
            ))}
          </ul>
        </section>

        <section className={`${styles.socialMedia} ${styles.section}`}>
          <h2>{strings.gameDescription.socialMedia.title}</h2>
          <ul>
            <li>
              <a
                href="https://www.youtube.com/@Osolitaire"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  className={styles.youtubeIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61554153020153"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className={styles.facebookIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/osolitairecom"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  className={styles.twitterIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://vk.com/club223614034"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <FontAwesomeIcon icon={faVk} className={styles.vkIcon} />
              </a>
            </li>
          </ul>
        </section>

        <section className={`${styles.multiSectionOne} ${styles.section}`}>
          <div id="yandex_rtb_R-A-2535917-3"></div>
          {window.yaContextCb.push(() => {
            // eslint-disable-next-line
            Ya.Context.AdvManager.render({
              blockId: "R-A-2535917-3",
              renderTo: "yandex_rtb_R-A-2535917-3",
            });
          })}
        </section>

        <section className={`${styles.faq} ${styles.section}`}>
          <h2>{strings.gameDescription.faq.title}</h2>
          <ul className={styles.faqList}>
            {strings.gameDescription.faq.questions.map((faq, index) => (
              <li className={styles.faqItem} key={index}>
                <h3 className={styles.faqQuestion}>{faq.question}</h3>
                <p className={styles.faqAnswer}>{faq.answer}</p>
              </li>
            ))}
          </ul>
          <button
            className={styles.askButton}
            onClick={() => {
              navigateTo("/contact");
            }}
          >
            {strings.gameDescription.faq.askButton}
          </button>
        </section>

        <footer>
          <p>&copy; {strings.footer}</p>
        </footer>
      </div>
    </div>
  );
};

HomePageDetails.propTypes = {
  gamesList: PropTypes.array.isRequired,
};

export default HomePageDetails;
