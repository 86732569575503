/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";
import style from "./game.module.css";

import { useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import PageMetadata from "../../components/PageMetadata";
import GameToolbar from "../../components/GameToolbar/GameToolbar";
import GameDetails from "./GameDetails";
import { strings } from "../../assets/localization";
import GamePageMeta from "../../components/helmet/GamePageMeta";

function GamePage() {
  const params = useParams();
  // const navigateTo = useNavigate();
  const [game, setGame] = useState(null);
  const [gameId, setGameId] = useState(null);
  const iframeRef = useRef(null);
  const gamedescriptionRef = useRef(null);

  let browserLanguage = navigator.language || navigator.userLanguage || "en";
  // let language = "ru";
  let language = strings.getLanguage();

  if (browserLanguage.includes("ru")) {
    language = "ru";
  } else {
    language = "en";
  }

  console.log("BROWSER_LANG: " + browserLanguage + " | " + language);

  // strings.setLanguage("EN");

  // console.log(language);

  // if (!localStorage.getItem("games")) navigateTo("/");
  // const currentGameData = JSON.parse(localStorage.getItem("games"))[language][
  //   params.id
  // ];

  //Fetching Games
  useEffect(() => {
    // const storedGames = sessionStorage.getItem("games");

    // if (!JSON.parse(storedGames)) {
    //   setAllGames(JSON.parse(storedGames));
    //   return;
    // }

    async function getGameDocuments() {
      let loadedGames = {};
      console.log("fetching games");

      const querySnapshot = await getDocs(collection(db, "games"));

      querySnapshot.forEach((doc) => {
        loadedGames = { ...loadedGames, [doc.id]: doc.data() };
        // loadedGame = localGames[language][params.id];
      });
      console.log(loadedGames);

      console.log("GAME_ID: " + params.id);

      setGameId(params.id);
      setGame(loadedGames[language][params.id]);
    }

    getGameDocuments();
  }, []);

  function gameUrl() {
    if (game) {
      return game.url + "&lang=" + language;
    } else {
      return "";
    }
  }

  // function showFullscreenAd() {
  //   console.log("SHOW_FULLSCREEN_AD");

  //   window.yaContextCb.push(() => {
  //     Ya.Context.AdvManager.render({
  //       blockId: "R-A-2535917-2",
  //       type: "fullscreen",
  //       platform: "desktop",
  //     });
  //   });
  // }

  const handleLike = () => {
    // Handle like button click
    console.log("Liked!");
  };

  const handleDislike = () => {
    // Handle dislike button click
    console.log("Disliked!");
  };

  const handleOnGameInfo = () => {
    console.log("Arrow down clicked!");

    if (gamedescriptionRef) {
      gamedescriptionRef.current.scrollIntoView();
    }
  };

  const handleEnterFullscreen = () => {
    console.log("Enter fullscreen clicked!");

    if (!iframeRef) return;

    iframeRef.current.requestFullscreen();
  };

  const handlePlayNow = () => {
    console.log("Play now clicked!");

    if (!iframeRef) return;

    iframeRef.current.scrollIntoView();
  };

  return (
    <>
      {game && <GamePageMeta gameId={gameId} logo={game.logo} />}
      <div className={style.appContainer}>
        {game && (
          <div className={style.iframeContainer}>
            <div className={style.iframeContainerInner}>
              <iframe
                title="Klondike Solitaire"
                className={style.solitaireIframe}
                allowFullScreen
                ref={iframeRef}
                src={gameUrl()}
              ></iframe>
              <div className={style.multiContainer}>
                <div id="yandex_rtb_R-A-2535917-1"></div>
                {window.yaContextCb.push(() => {
                  Ya.Context.AdvManager.render({
                    blockId: "R-A-2535917-1",
                    renderTo: "yandex_rtb_R-A-2535917-1",
                  });
                })}
              </div>
            </div>
            <GameToolbar
              onLike={handleLike}
              onDislike={handleDislike}
              onGameInfo={handleOnGameInfo}
              onEnterFullscreen={handleEnterFullscreen}
            />
          </div>
        )}
        {gameId && (
          <GameDetails
            reference={gamedescriptionRef}
            gameId={gameId}
            onPlay={handlePlayNow}
          />
        )}
      </div>
    </>
  );
}

export default GamePage;
