export let localizationIt = {
  gameDescription: {
    header: {
      golf: {
        title: "Play Golf Solitaire Online for Free",
        content:
          "Play Golf Solitaire online for free! Enjoy a unique twist on the classic card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      yukon: {
        title: "Play Yukon Solitaire Online for Free",
        content:
          "Play Yukon Solitaire online for free! Test your skills with this challenging card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      pyramid: {
        title: "Play Pyramid Solitaire Online for Free",
        content:
          "Play Pyramid Solitaire online for free! Challenge yourself with this intriguing card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      freecell: {
        title: "Play FreeCell Solitaire Online for Free",
        content:
          "Play FreeCell Solitaire online for free! Challenge yourself with this classic card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      tripeaks: {
        title: "Play Tri Peaks Solitaire Online for Free",
        content:
          "Play Tri Peaks Solitaire online for free! Enjoy a thrilling variation of the classic card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      canfield: {
        title: "Play Canfield Solitaire Online for Free",
        content:
          "Play Canfield Solitaire online for free! Test your skills with this classic card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      spider: {
        title: "Play Spider Solitaire Online for Free",
        content:
          "Play Spider Solitaire online for free! Test your skills with this classic card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      klondike: {
        title: "Play Klondike Solitaire Online for Free",
        content:
          "Play Klondike Solitaire online for free! Enjoy classic card fun anytime, anywhere. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
      russian: {
        title: "Play Russian Solitaire Online for Free",
        content:
          "Play Russian Solitaire online for free! Enjoy a unique variation of the classic card game. Whether you're a seasoned player or new to the game, dive in and experience endless entertainment with us!",
      },
    },
    instructions: {
      klondike: {
        title: "How to Play Klondike Solitaire",
        content:
          "Klondike Solitaire is a classic card game that tests your strategy and patience. Follow these steps to enjoy the game:",
        steps: [
          "Arrange the deck in tableau columns. Start with a single card in the leftmost column, then add six cards to the next column, five to the next, and so on.",
          "Build down in alternating colors. For example, you can place a red 6 on a black 7.",
          "Move cards between tableau columns to uncover hidden cards. You can move a sequence of cards if they are in descending order and of the same suit.",
          "Place Kings into empty tableau columns. An empty column can only be filled with a King.",
          "Build the foundations in ascending order. Start with an Ace and build up to the King for each suit.",
        ],
        video: {
          src: "https://youtu.be/oAB5fsN2uA8?si=BDjRd52qmwkAP_Ml",
          title: "Klondike Solitaire Instructional Video",
        },
      },
      golf: {
        title: "How to Play Golf Solitaire",
        content:
          "Golf Solitaire is a unique variation of the classic card game that requires skill and strategy. Follow these steps to enjoy the game:",
        steps: [
          "Start with a tableau of seven columns, each containing a single face-up card.",
          "The remaining deck forms the stock pile, and the top card is placed face-up next to it as the foundation.",
          "Cards from the tableau can be moved to the foundation if they are one rank higher or lower than the top card of the foundation, regardless of suit.",
          "Empty tableau columns can be filled with any available card.",
          "The game is won when all cards are successfully moved to the foundation.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=W8gEKBayxFI&ab_channel=wikiHow",
          title: "Golf Solitaire Instructional Video",
        },
      },
      yukon: {
        title: "How to Play Yukon Solitaire",
        content:
          "Yukon Solitaire is a challenging card game that tests your skills. Follow these steps to enjoy the game:",
        steps: [
          "Start with a tableau of seven columns, each containing a varying number of face-down and face-up cards.",
          "The goal is to build each foundation suit in ascending order from Ace to King.",
          "Cards in the tableau can be moved as a sequence if they are in descending order and of the same suit.",
          "Spaces in the tableau can only be filled with a King or a sequence starting with a King.",
          "The game is won when all cards are successfully moved to the foundation.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=bi04SVsuBK8&ab_channel=WorldofSolitaire",
          title: "Yukon Solitaire Instructional Video",
        },
      },
      pyramid: {
        title: "How to Play Pyramid Solitaire",
        content:
          "Pyramid Solitaire is an intriguing card game that requires careful planning. Follow these steps to enjoy the game:",
        steps: [
          "Start with a pyramid of cards, and the goal is to remove all pairs of cards that add up to 13.",
          "Kings have a value of 13 and can be removed on their own. Queens have a value of 12, Jacks have a value of 11, and so on.",
          "Cards can only be removed if they are not covered by other cards.",
          "You can draw cards from the deck to help you uncover and match cards in the pyramid.",
          "The game is won when all pairs are successfully removed, or the pyramid is completely cleared.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=p5ebvIyz7T4&ab_channel=wikiHow",
          title: "Pyramid Solitaire Instructional Video",
        },
      },
      freecell: {
        title: "How to Play FreeCell Solitaire",
        content:
          "FreeCell Solitaire is a classic card game that challenges your strategy. Follow these steps to enjoy the game:",
        steps: [
          "Arrange the cards in eight tableau columns with the first four containing seven cards and the remaining four containing six cards.",
          "Build down the tableau columns in alternating colors. For example, you can place a red 6 on a black 7.",
          "FreeCells act as temporary storage. Use them strategically to move cards around and access hidden cards.",
          "Build foundations in ascending order, starting with Aces. Each foundation represents a suit.",
          "The game is won when all cards are moved to the foundations in the correct order.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=iAa_2_oRXJw&ab_channel=wikiHow",
          title: "FreeCell Solitaire Instructional Video",
        },
      },
      tripeaks: {
        title: "How to Play Tri Peaks Solitaire",
        content:
          "Tri Peaks Solitaire is a thrilling variation of the classic card game. Follow these steps to enjoy the game:",
        steps: [
          "Clear the tableau by selecting cards that are one higher or one lower than the open card at the bottom.",
          "Continue clearing the tableau until no more moves are possible.",
          "Use wild cards strategically to uncover hidden cards and create new move opportunities.",
          "The game is won when all cards are cleared from the tableau.",
          "Strategize your moves to achieve a high score and complete each level.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=J8tFipxzsP4&t=17s&ab_channel=wikiHow",
          title: "Tri Peaks Solitaire Instructional Video",
        },
      },
      canfield: {
        title: "How to Play Canfield Solitaire",
        content:
          "Canfield Solitaire is a classic card game that tests your skills. Follow these steps to enjoy the game:",
        steps: [
          "Deal 13 cards face down in a single pile (the reserve). The top card of the reserve is face up and can be played.",
          "Build the tableau in descending order and alternating colors. For example, you can place a black 6 on a red 7.",
          "Move cards to the foundation in ascending order, starting with Aces.",
          "Empty tableau columns can only be filled with a King.",
          "The game is won when all cards are moved to the foundation.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=EwcHlxtCt9U&ab_channel=GatherTogetherGames",
          title: "Canfield Solitaire Instructional Video",
        },
      },
      spider: {
        title: "How to Play Spider Solitaire",
        content:
          "Spider Solitaire is a classic card game that challenges your skills. Follow these steps to enjoy the game:",
        steps: [
          "Deal 54 cards into 10 tableau piles, with only the top card face up initially.",
          "Create sequences of descending cards in the tableau, regardless of suit. A King can be moved to an empty spot.",
          "Complete sequences of 13 cards from King to Ace to remove them from the tableau.",
          "Build suits in the foundation area in ascending order, starting with Ace.",
          "The game is won when all suits are built in the foundation.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=pZVH6EfYHhI&ab_channel=wikiHow",
          title: "Spider Solitaire Instructional Video",
        },
      },
      russian: {
        title: "How to Play Russian Solitaire",
        content:
          "Russian Solitaire is a unique variation of the classic card game. Follow these steps to enjoy the game:",
        steps: [
          "Start with a standard deck of 52 cards and deal them into 13 piles of 4 cards each.",
          "Build down in alternating colors. For example, you can place a red 6 on a black 7.",
          "Move cards between piles to uncover hidden cards. You can move a sequence of cards if they are in descending order and of the same suit.",
          "Empty piles can only be filled with Kings.",
          "Build the foundations in ascending order. Start with an Ace and build up to the King for each suit.",
          "The game is won when all cards are successfully moved to the foundations.",
        ],
        video: {
          src: "https://www.youtube.com/watch?v=y8az6GsDP80&ab_channel=Solitaired",
          title: "Russian Solitaire Instructional Video",
        },
      },
    },
    strategy: {
      title: "Game Strategy",
      content:
        "Mastering Klondike Solitaire requires both skill and strategy. Here are some tips to improve your game:",
      tips: [
        "Always prioritize uncovering hidden cards in the tableau.",
        "Try to create empty tableau columns to move Kings into.",
        "Build your foundations wisely to create space for tableau moves.",
        "Don't be afraid to undo moves and explore different strategies.",
        "Plan your moves ahead to increase your chances of winning.",
      ],
    },
    detailedDescription: {
      klondike: {
        title: "Play Klondike Solitaire Online",
        content:
          "Immerse yourself in the classic card game experience with our online Klondike Solitaire. Enjoy endless hours of entertainment as you strategize and play through the familiar solitaire rules. Whether you are a seasoned card player or new to the game, our Klondike Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fklondike-screenshot.png?alt=media&token=5c57c3b2-b363-4ff1-99f6-a569854ab079",
      },
      golf: {
        title: "Play Golf Solitaire Online",
        content:
          "Immerse yourself in the unique card game experience with our online Golf Solitaire. Enjoy endless hours of entertainment as you strategize and play through the unique solitaire rules. Whether you are a seasoned card player or new to the game, our Golf Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fgolf-screenshot.png?alt=media&token=6dc8ee07-6e51-472b-bea9-aafce0d8ed58",
      },
      yukon: {
        title: "Play Yukon Solitaire Online",
        content:
          "Immerse yourself in the challenging card game experience with our online Yukon Solitaire. Enjoy endless hours of entertainment as you strategize and play through the challenging solitaire rules. Whether you are a seasoned card player or new to the game, our Yukon Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fyukon-screenshot.png?alt=media&token=70fcc43b-8f1d-4385-a9c6-843f697ae65b",
      },
      pyramid: {
        title: "Play Pyramid Solitaire Online",
        content:
          "Immerse yourself in the intriguing card game experience with our online Pyramid Solitaire. Enjoy endless hours of entertainment as you strategize and play through the intriguing solitaire rules. Whether you are a seasoned card player or new to the game, our Pyramid Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fpyramid-screenshot.png?alt=media&token=1cce4d5c-6e75-48c5-becb-2564f5913947",
      },
      freecell: {
        title: "Play FreeCell Solitaire Online",
        content:
          "Immerse yourself in the classic card game experience with our online FreeCell Solitaire. Enjoy endless hours of entertainment as you strategize and play through the classic solitaire rules. Whether you are a seasoned card player or new to the game, our FreeCell Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Ffreecell-screenshot.png?alt=media&token=b460ed7b-2f67-40a8-a446-23ab22cd3835",
      },
      tripeaks: {
        title: "Play Tri Peaks Solitaire Online",
        content:
          "Immerse yourself in the thrilling card game experience with our online Tri Peaks Solitaire. Enjoy endless hours of entertainment as you strategize and play through the thrilling solitaire rules. Whether you are a seasoned card player or new to the game, our Tri Peaks Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Ftripeaks-screenshot.png?alt=media&token=06ea0716-b951-4656-92a4-90165b5cecfb",
      },
      canfield: {
        title: "Play Canfield Solitaire Online",
        content:
          "Immerse yourself in the classic card game experience with our online Canfield Solitaire. Enjoy endless hours of entertainment as you strategize and play through the classic solitaire rules. Whether you are a seasoned card player or new to the game, our Canfield Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fcanfield-screenshot.png?alt=media&token=e1c37d47-1b89-4fd7-af90-31497a4f5664",
      },
      spider: {
        title: "Play Spider Solitaire Online",
        content:
          "Immerse yourself in the classic card game experience with our online Spider Solitaire. Enjoy endless hours of entertainment as you strategize and play through the classic solitaire rules. Whether you are a seasoned card player or new to the game, our Spider Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Fspider-screenshot.png?alt=media&token=09e40372-4ba7-4124-8cdd-f5de004c01a8",
      },
      russian: {
        title: "Play Russian Solitaire Online",
        content:
          "Immerse yourself in the unique card game experience with our online Russian Solitaire. Enjoy endless hours of entertainment as you strategize and play through the unique solitaire rules. Whether you are a seasoned card player or new to the game, our Russian Solitaire offers a user-friendly interface for an enjoyable gaming experience.",
        screenshot:
          "https://firebasestorage.googleapis.com/v0/b/osolitaire-973ef.appspot.com/o/screenshots%2Frus-screenshot.png?alt=media&token=acf3ae0a-373c-40da-8a2b-c8c2165bcff9",
      },
      features: [
        {
          title: "Easy to Play",
          content:
            "Intuitive controls for an enjoyable gaming experience, suitable for players of all skill levels.",
        },
        {
          title: "Responsive Design",
          content:
            "Play Klondike Solitaire on various devices, including desktops, tablets, and mobile phones.",
        },
        {
          title: "Unlimited Undos",
          content:
            "Undo your moves without any limit, allowing you to perfect your strategy.",
        },
        {
          title: "Endless Games",
          content:
            "Play unlimited games and enjoy the Klondike Solitaire experience without any restrictions.",
        },
      ],
    },
    faq: {
      title: "Frequently Asked Questions",
      questions: [
        {
          question: "Is there a mobile app available for your service?",
          answer:
            "Yes, we offer a mobile app for Android devices. You can download it from Google Play.",
        },
        {
          question: "Is your solitaire game genuinely free to play?",
          answer:
            "Absolutely! Our solitaire game is entirely free, providing unlimited entertainment without any hidden fees.",
        },
        {
          question:
            "Do I need to create an account to enjoy your free solitaire game?",
          answer:
            "No account creation required! Immerse yourself in our free solitaire game hassle-free, with no need for registration.",
        },
        {
          question:
            "How secure is my data when playing your free solitaire game?",
          answer:
            "Your data security is our priority. Our free solitaire game employs robust encryption measures and follows industry standards to ensure the confidentiality and integrity of your personal information.",
        },
      ],
      askButton: "Ask a Question",
    },
    reviews: {
      title: "Our Players Reviews",
      reviews: [
        {
          content: '"Awesome game! Can\'t get enough of it. Highly recommend!"',
          stars: 5,
          author: "John Doe",
        },
        {
          content: '"Simple and addictive. Perfect for a quick break."',
          stars: 5,
          author: "Jane Smith",
        },
        {
          content:
            '"Amazing game! It\'s a great way to unwind after a long day. The controls are intuitive, and the interface is clean."',
          stars: 4,
          author: "Alex Johnson",
        },
      ],
      leaveReviewButton: "Leave a review",
    },
    socialMedia: {
      title: "Follow Us for Updates",
      icons: [
        { link: "https://www.youtube.com/@Osolitaire", icon: "faYoutube" },
        {
          link: "https://www.facebook.com/profile.php?id=61554153020153",
          icon: "faFacebook",
        },
        { link: "https://twitter.com/osolitairecom", icon: "faXTwitter" },
        { link: "https://vk.com/club223614034", icon: "faVk" },
      ],
    },
    playNow: {
      title: "Play Now!",
      content: "Experience the excitement of Klondike Solitaire.",
      cta: "Play now!",
    },
  },
  footer: "2024 Osolitaire.com. All rights reserved.",
};
