import styles from "./contact.module.css";
import emailjs from "@emailjs/browser";
import NavigationBar from "../../components/Navbar/Navbar";
import { strings } from "../../assets/localization";

function ContactPage() {
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(e.target);
    console.log(Object.fromEntries(new FormData(e.target)));
    console.log(document.getElementById("name").value);
    const params = {
      from_name: document.getElementById("name").value,
      from_email: document.getElementById("email").value,
      message: document.getElementById("message").value,
    };

    emailjs
      .send("service_x1j0b7u", "template_dwpyc3x", params, "ofS9mYOUyXQ1OZQDE")
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);

          e.target.reset();
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <NavigationBar />
      <div className={styles.contactPage}>
        <div className={styles.contactContainer}>
          <h1>Contact Us</h1>
          <form
            onSubmit={(e) => {
              onSubmit(e);
            }}
          >
            <label>
              <span>{strings.contact.name}</span>
              <input
                id="name"
                className={styles.inputField}
                placeholder={strings.contact.namePlaceholder}
                required
                type="text"
              />
            </label>
            <label>
              <span>{strings.contact.email}</span>
              <input
                id="email"
                className={styles.inputField}
                placeholder={strings.contact.emailPlaceholder}
                required
                type="email"
              />
            </label>
            <label>
              <span>{strings.contact.message}</span>
              <textarea
                id="message"
                className={styles.textareaField}
                required
                placeholder={strings.contact.messagePlaceholder}
              ></textarea>
            </label>
            <button className={styles.submitButton} type="submit">
              {strings.contact.sendButton}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
